nav {
  display: none;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  width: 100%;
  z-index: 5;
}

.nav-links {
  display: none;
  width: 100%;
  background-color: black;
}

.burger {
  display: none;
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  margin-right: 100px;
  z-index: 5;
  position: absolute;
}

@media screen and (max-width: 750px) {
  .nav-links {
    position: absolute;
    left: 0px;
    height: 100px;
    width: 280px;
    top: 90px;
    display: flex;
    flex-direction: column;
    min-width: 30%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  .burger {
    display: block;
  }
  nav {
    display: flex;
  }
}

.nav-active {
  opacity: 1;
  transform: translateX(0%);
  display: block;
}
