.thumbModal {
  display: none;
  width: 100%;
}

.modalImage {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.active {
  display: flex;
  position: absolute;
  top: 70px;
  cursor: zoom-out;
}
